@import "flatpickr/dist/themes/material_blue.css";
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  .nav-item-active {
    @apply bg-white bg-opacity-20;
  }
  .nav-item {
    @apply hover:bg-white hover:bg-opacity-20;
  }

  .theme-bg {
    background-color: #3959BB;
  }
  
}

